<template>
  <div class="'Publications'">
    <PageTitle :title="$t('Publications')" />
    <b-container fluid="xxl">
      <b-row>
        <b-col cols="8">
          <p>Le présent répertoire réunit les publications qui sont nées d'abord dans le cadre du projet des DocLing (depuis 2001), puis dans celui des autres
          volets de GallRom (depuis 2022). Les titres sont structurés selon les thématiques principales et accompagnés d'une synthèse très succincte.</p>
          <p>Les sigles des travaux (= nom de l'auteur + date) renvoient par un hyperlien aux indications bibliographiques intégrales dans GallRomBibl££; la plupart des études sont téléchargeables ci-dessous.</p>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="8">
          <h3>1. Présentation des différents volets de GallRom</h3>
          <h4>1.1. Présentation des DocLing et questions d’édition</h4>
          <p class="public" v-for="(publication, index) in publications['1.1']" :key="`public-1-1-${index}`">
            <span v-html="publication"></span>
          </p>
          <h4>1.2. Présentation de LEGaMe: DocLingGloss, DAG, DEAG et TEAF</h4>
          <p class="public" v-for="(publication, index) in publications['1.2']" :key="`public-1-2-${index}`">
            <span v-html="publication"></span>
          </p>
          <h4>1.3. Présentation de GallRom et de GallRomBibl</h4>
          <p class="public" v-for="(publication, index) in publications['1.3']" :key="`public-1-3-${index}`">
            <span v-html="publication"></span>
          </p>
          <h3>2. Lexicologie et lexicographie (électroniques)</h3>
          <h4>2.1. Aspects divers des dictionnaires et données lexicales réunis dans LEGaMe</h4>
          <p class="public" v-for="(publication, index) in publications['2.1']" :key="`public-2-1-${index}`">
            <span v-html="publication"></span>
          </p>
          <h4>2.2. Travaux lexicologiques et lexicographiques</h4>
          <p class="public" v-for="(publication, index) in publications['2.2']" :key="`public-2-2-${index}`">
            <span v-html="publication"></span>
          </p>
          <h3>3. Analyses scriptologiques, genres textuels et diasystème</h3>
          <h4>3.1. Travaux méthodologiques</h4>
          <p class="public" v-for="(publication, index) in publications['3.1']" :key="`public-3-1-${index}`">
            <span v-html="publication"></span>
          </p>
          <h4>3.2. Études du territoire d’oïl</h4>
          <p class="public" v-for="(publication, index) in publications['3.2']" :key="`public-3-2-${index}`">
            <span v-html="publication"></span>
          </p>
          <h4>3.3. Études des territoires francoprovençal, occitan et gascon</h4>
          <p class="public" v-for="(publication, index) in publications['3.3']" :key="`public-3-3-${index}`">
            <span v-html="publication"></span>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style>
  .subline {
    margin-left: 20px;
    margin-top: 5px;
    font-size: 95%;
  }
  h3, h4 {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .tooltiptext {
    visibility: hidden;
    width: 50%;
    background-color: #5b5b5b;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: -20px 0;

    /* Position the tip */
    position: absolute;
    z-index: 1;
    /*
    left: 50%;
    top: -10%;
    */
    margin-top: -3vw;
    margin-left: 100%;
  }
  .public:hover .tooltiptext {
    visibility: visible;
  }
</style>

<script>
  import PageTitle from '@/components/PageTitle.vue'

  export default {
    name: 'Publications',
    data() {
      return {
        publications: {
          '1.1': [
            "Glessgen 2001, <i>Das altfranzösische Geschäftsschrifttum in Oberlothringen: Quellenlage und Deutungsansätze.</i> [<a href=\"/publications/Glessgen_2001.pdf\">PDF</a>]<div class=\"subline\">[présentation générale et prospective du projet; étude de faisabilité]</div><span class=\"tooltiptext\">, in: Kurt Gärtner / Günter Holtus / Andrea Rapp / Harald Völker (éds): <i>Skripta, Schreiblandschaften und Standardisierungstendenzen</i> (Beiträge zum Kolloquium vom 16. bis 18. September 1998 in Trier), Trier, THF, 257–294</span>",
            "Glessgen 2003a, <i>L'élaboration philologique et l'étude lexicologique des Plus anciens documents linguistiques de la France à l'aide de l'informatique.</i> [<a href=\"/publications/Glessgen_2003a.pdf\">PDF</a>]<div class=\"subline\">[première présentation du projet en cours; critères d’édition et inventaire des balises éditoriales</div><span class=\"tooltiptext\">, in: Frédéric Duval (éd.): <i>Frédéric Godefroy. Actes du X<sup>e</sup> colloque international sur le moyen français</i>, Paris, Ecole des Chartes, 371–386</span>",
            "Glessgen 2005a, <i>Editorische, lexikologische und graphematische Erschließung altfranzösischer Urkundentexte mit Hilfe von TUSTEP. Stand der Arbeiten.</i> [<a href=\"/publications/Glessgen_2005a.pdf\">PDF</a>]<div class=\"subline\">[version en allemand de Glessgen 2003a, légèrement élargie]</div><span class=\"tooltiptext\">, in: Gärtner, Kurt / Holtus, Günter (éds): <i>Drittes Trierer Urkundensprachekolloquium</i> (20.-22. Juni 2001), Trier, THF, 91–107</span>",
            "Glessgen 2006b, <i>L'écrit documentaire dans l'histoire linguistique de la France.</i> [<a href=\"/publications/Glessgen_2006b_Kopie.pdf\">PDF</a>]<div class=\"subline\">[synthèse des aspects méthodologiques du projet des DocLing dans une perspective historiographique]</div><span class=\"tooltiptext\">, in: Guyotjeannin, Olivier (éd.): La langue des actes. Actes du XI<sup>e</sup> congrès de la Commission internationale de diplomatique (Troyes, 11–13 septembre 2003), Éditions en ligne de l'École des Chartes (<a href=\"www.enc.sorbonne.fr/editions-en-ligne.html\">elec</a>)</span>",
            "Glessgen 2007a, <i>Bases de données textuelles et lexicographie historique: l'exemple des</i> Plus anciens documents linguistiques de la France. [<a href=\"/publications/Glessgen_2007a.pdf\">PDF</a>]<div class=\"subline\">[brève présentation générale de l'état du projet et de ses perspectives en 2005]</div><span class=\"tooltiptext\">, in: Harald Völker / Lene Schøsler / Martin Glessgen (éds): <i>De la philologie aux nouveaux médias : éditions de textes – linguistique de corpus – analyse informatique du langage</i>, in: David A. Trotter (éd.): <i>Actes du XXIV<sup>e</sup> Congrès International de Linguistique et Philologie Romanes</i> (sept. 2004, Aberystwyth), Tübingen, Niemeyer. Vol. 1 (Section 2), 373–380</span>",
            "Glessgen 2010a, <i>Élaborations philologiques et linguistiques sur la base de corpus textuels en français ancien – architecture du projet.</i> [<a href=\"/publications/Glessgen_2010a.pdf\">PDF</a>]<div class=\"subline\">[brève présentation générale de l’architecture du projet en 2008]</div><span class=\"tooltiptext\">, in: Maria Iliescu / Heidi Siller-Runggaldier / Paul Danler (éds), <i>Actes du XXV<sup>e</sup> Congrès International de Linguistique et Philologie Romanes (sept. 2007, Innsbruck)</i>, Berlin, De Gruyter. Tome 6 (section XIII – <i>Philologie et linguistique des textes anciens; lexicographie diachronique</i>), 135–149</span>",
            "Glessgen/Kihaï/Videsott 2010, <i>L’élaboration philologique et linguistique des « Plus anciens documents linguistiques de la France, Édition électronique ». Trois études.</i> [<a href=\"/publications/Glessgen-Kihai-Videsott_2010.pdf\">PDF</a>]<div class=\"subline\">[comporte (i) une Présentation générale détaillée de la genèse, de l'architecture et de méthodologie du projet, (iia) l'étude linguistique, philologique et historique d'un acte lorrain épiscopal de 1237 et (iib) du plus ancien document en français de la chancellerie royale capétienne, (iii) une présentation des paramètres diplomatiques et paléographiques pris en considération pour l'identification des lieux d'écriture ainsi que (iv) la présentation de la strucure de la base de données lexicologique envisagée]</div><span class=\"tooltiptext\">, réunies par Martin-D. Gleßgen, Dumitru Kihaï et Paul Videsott, <i>Bibliothèque de l’École des Chartes</i> 168 (2010) [2011], 5–94</span>",
            "Glessgen 2011, <i>Le long chemin d’une charte vers l’ère multimédiale.</i> [<a href=\"/publications/Glessgen_2011b_Kopie.pdf\">PDF</a>]<div class=\"subline\">[présentation de la partie éditoriale des première et deuxième versions du site des DocLing (2009 et 2013) ainsi que de la plateforme pédagogique <i>Ad fontes</i>]</div><span class=\"tooltiptext\">, in: Wolfgang Schweickard / Anja Overbeck / Harald Völker (éds), <i>Lexikon, Varietät, Philologie. Romanistische Studien. Günter Holtus zum 65. Geburtstag</i>, Berlin, de Gruyter, 649–664</span>",
            "Glessgen 2015, <i>L'écrit documentaire et le projet des Plus anciens documents linguistiques de la France.</i> [<a href=\"/publications/Glessgen_2015.pdf\">PDF</a>]<div class=\"subline\">[présentation détaillée de l'évolution du projet des DocLing depuis Paul Meyer, Clovis Brunel et Jacques Monfrin (méthodes, couverture géo-chronologique, état des sources)]</div><span class=\"tooltiptext\">, in: David Trotter (éd.): <i>Manuel de la philologie de l’édition</i> (Manuals of Romance Linguistics/MRL, 4), Berlin / Boston, De Gruyter, 267–295</span>",
          ],
          '1.2': [
            "Glessgen 2018, <i>Les ‘Documents linguistiques’ de la France. Histoire, présent et perspectives d’un projet centenaire.</i>[<a href=\"/publications/Glessgen_2018.pdf\">PDF</a>]<div class=\"subline\">[présentation générale, rétro- et prospective du projet des DocLing dans sa troisième version (2016) ainsi que des volets lexicographique et scriptologique en cours d'élaboration]</div><span class=\"tooltiptext\">, <i>Comptes rendus des séances de l'Académie des Inscriptions et Belles-Lettres (CRAI)</i> 161/3 [juill. à oct. 2017], 2018, 1261–1292</span>",
            "Glessgen 2023a, <i>Les</i> Documents et analyses linguistiques de la Galloromania médiévale <i>(GallRom): structure et potentiel interprétatif.</i>[<a href=\"/publications/Glessgen_2023a_Kopie.pdf\">PDF</a>]<div class=\"subline\">[présentation de la structure intégrale de la base de données GallRom]</div><span class=\"tooltiptext\">, in: <i>Perspectives de recherche en linguistique et philologie romanes.</i> Textes choisis par la Société de linguistique romane, édités par Dolores Corbella, Josefa Dorta et Rafael Padrón, Strasbourg, SLR/ÉLiPhi, vol. 2, 1025–1044</span>",
            "Glessgen/Robecchi 2024, <i>La base de données bibliographique du D(E)AG et du D(E)AO. Méthodologie informatique et philologique.</i>[<a href=\"/publications/Glessgen_2024.pdf\">PDF</a>]<div class=\"subline\">[présentation détaillée des principes méthodologiques et de l'architecture de GallRomBibl]</div><span class=\"tooltiptext\">, in: Caterina Menichetti / Federica Fusaroli / Aude Sartenar (éds.), <i>La littérature occitane médiévale dans sa tradition manuscrite. Témoins, traditions, corpora</i>, Roma, Viella</span>"
          ],
          '1.3': [
            "Glessgen/Kopp 2005, <i>Linguistic annotation of texts in non-standardized languages: the program procedures of the tool Phoenix.</i>[<a href=\"/publications/Glessgen_Kopp_2005_Kopie.pdf\">PDF</a>]<div class=\"subline\">[présentation du système <i>Phoenix-1</i>, développé pour la gestion de la première version des DocLing]</div><span class=\"tooltiptext\">, in: Claus Pusch / Johannes Kabatek / Wolfgang Raible (éds), <i>Romanistische Korpuslinguistik II: Korpora und diachrone Sprachwissenschaft / Romance Corpus Linguistics II: Corpora and Diachronic Linguistics</i> (ScriptOralia, 130), Tübingen, Narr, 147–154</span>",
            "Glessgen 2006c, <i>Esigenze della tecnologia informatica nella filologia e lessicografia storica.</i>[<a href=\"/publications/Glessgen_2006c_Kopie.pdf\">PDF</a>]<div class=\"subline\">[réflexion méthodologique sur les bases de données lexicographiques de la romanistique diachronique]</div><span class=\"tooltiptext\">, in: Wolfgang Schweickard (éd.): <i>Nuovi media e lessicografia storica.</i> Atti del colloquio in occasione del settantesimo compleanno di Max Pfister, Tübingen, Niemeyer, 15–24</span>",
            "Glessgen/Stein 2005, <i>Resources and tools for analyzing Old French texts.</i>[<a href=\"/publications/Glessgen_Stein_2005_Kopie.pdf\">PDF</a>]<div class=\"subline\">[présentation du lemmatiseur d'ancien français développé pour le système de gestion de données <i>Phoenix-1</i>]</div><span class=\"tooltiptext\">, in: Claus Pusch / Johannes Kabatek / Wolfgang Raible (éds), <i>Romanistische Korpuslinguistik II: Korpora und diachrone Sprachwissenschaft / Romance Corpus Linguistics II: Corpora and Diachronic Linguistics</i> (ScriptOralia, 130), Tübingen, Narr, 135–145</span>",
            "Glessgen 2007b, <i>Philologie und Sprachgeschichtsschreibung in der Romanistik: Die 'informatische Wende'.</i>[<a href=\"/publications/Glessgen_2007b_Kopie.pdf\">PDF</a>]<div class=\"subline\">[réflexion méthodologique sur les bases de données lexicographiques de la romanistique diachronique]</div><span class=\"tooltiptext\">, in: Michael Stolz (éd.): Edition und Sprachgeschichte. Baseler Fachtagung 2.-4. März 2005, Tübingen, Niemeyer (Beihefte zur editio 26), 201–212</span>",
          ],
          '2.1': [
            "Glessgen 2003b, <i>La lemmatisation de textes d’ancien français: méthodes et recherches.</i>[<a href=\"/publications/Glessgen_2003b_Kopie.pdf\">PDF</a>]<div class=\"subline\">[réflexion méthodologique sur les finalités et la réalisation pratique de la lemmatisation]</div><span class=\"tooltiptext\">, in: Pierre Kunstmann / France Martineau / Danielle Forget (éds): <i>Ancien et moyen français sur le Web: enjeux méthodologiques et analyse du discours</i>, Ottawa, David, 55–75</span>",
            "Glessgen/Alletsgruber 2010, <i>Structure de la base de données lexicologique des Plus anciens documents.</i>[<a href=\"/publications/Glessgen_2010.pdf\">PDF</a>]<div class=\"subline\">[conception originelle d’un lexique électronique basé sur les DocLing]</div><span class=\"tooltiptext\">, <i>Bibliothèque de l’École des Chartes</i> 168 (2010) [2011], 83–94</span>",
            "Glessgen/Tittel 2018, <i>Le Dictionnaire d'ancien gascon électronique (DAGél).</i>[<a href=\"/publications/Glessgen_Tittel_2018_Kopie.pdf\">PDF</a>]<div class=\"subline\">[aperçu des différentes phases du DAG et présentation de la méthodologie pratique mise en œuvre pour le développement du DEAG]</div><span class=\"tooltiptext\">, in: Roberto Antonelli / Martin Glessgen / Paul Videsott (éds), <i>Atti del XXVIII<sup>°</sup> Congresso Internazionale di Linguistica e Filologia Romanza</i> (Roma, 2016), Strasbourg, SLR/ELiPhi, vol. 1, 799–813</span>",
            "Glessgen/Dallas 2019, <i>L'intégration du vocabulaire des Documents</i> linguistiques galloromans <i>dans le DEAF électronique».</i>[<a href=\"/publications/Glessgen_Dallas_2019_Kopie.pdf\">PDF</a>]<div class=\"subline\">[présentation de la méthodologie pratique mise en œuvre pour la fusion entre le vocabulaire des DocLing et le dictionnaire du DEAF, préparant ainsi le TEAF]</div><span class=\"tooltiptext\"> <i>Lexicographica</i> 35, 235–267</span>",
            "Glessgen 2021b, <i>Préface au DAG. Dictionnaire onomasiologique de l'ancien gascon</i>, fasc. 23[<a href=\"/publications/Glessgen_2021b_Kopie.pdf\">PDF</a>]<div class=\"subline\">[présentation succincte de l'architecture du DAG]</div><span class=\"tooltiptext\"> (dernier fascicule), rédigé par Nicoline Winkler. Forschungsstellenleiter Martin Glessgen, Berlin/Boston, De Gruyter, I–III</span>",
            "Glessgen 2022c, <i>Wörterbuch der altgaskognischen Urkundensprache / Dictionnaire de l'ancien gascon (DAG) / Dictionnaire électronique d'ancien gascon (DAGél).</i>[<a href=\"/publications/Glessgen_2022c_Kopie.pdf\">PDF</a>]<div class=\"subline\">[présentation succincte de la trajectoire du DAG et du <i>Dictionnaire étymologique d’ancien gascon</i> (DEAG)]</div><span class=\"tooltiptext\">in : <i>Heidelberger Akademie der Wissenschaften. Jahrbuch 2021</i>, Heidelberg, Akademie d. W., 2022, 202–207</span>",
          ],
          '2.2': [
            "Glessgen 2011a, <i>Le statut épistémologique du lexème.</i>[<a href=\"/publications/Glessgen_2011a_Kopie.pdf\">PDF</a>]<div class=\"subline\">[fondement théorique des principes lexicologiques mis en œuvre dans LEGaMe]</div><span class=\"tooltiptext\">, <i>Revue de Linguistique Romane</i> 75, 337–416</span>",
            "Glessgen/Trotter 2016, <i>La régionalité lexicale du français au Moyen Âge.</i>[<a href=\"/publications/Glessgen_Trotter_2016_Kopie.pdf\">PDF</a>]<div class=\"subline\">[inventaire de régionalismes et analyses thématiques de la régionalité lexicale dans des genres textuels divers]</div><span class=\"tooltiptext\">, Strasbourg, ÉLiPhi</span>",
            "Glessgen/Kihaï 2016, <i>La régionalité lexicale dans les textes documentaires.</i>[<a href=\"/publications/Glessgen_Kihai_2016_Kopie.pdf\">PDF</a>]<div class=\"subline\">[étude des régionalismes des DocLing, présents dans la ‘liste Roques’]</div><span class=\"tooltiptext\">, in: Martin Glessgen / David Trotter (éds): <i>La régionalité lexicale au Moyen Âge</i>, Strasbourg, ELiPhi, 2016, 341–375</span>",
            "Glessgen/Videsott 2018, <i>L’élaboration du lexique français médiéval – variation diatopique, sélection et change­ment lexicaux.</i>[<a href=\"/publications/Glessgen_Videsott_2018_Kopie.pdf\">PDF</a>]<div class=\"subline\">[étude de la distribution diatopique du lexique médiéval dans les textes documentaires et de la formation d'une norme lexicale]</div><span class=\"tooltiptext\">, <i>Romanistisches Jahrbuch</i> 68 (2017 [2018]), 64–89</span>",
            "FEWGuide = Hélène Carles / Marguerite Dallas / Martin Glessgen / André Thibault: <i>Französisches Etymologisches Wörterbuch: Guide d'utilisation</i>, Strasbourg, ELiPhi/SLR, 2019.[<a href=\"/publications/FEWGuide_Kopie.pdf\">PDF</a>]<div class=\"subline\">[guide d'utilisation du FEW comportant une description des différents dictionnaires et base de données de la Galloromania]</div>",
            "DRM = Hélène Carles / Martin Glessgen / Marco Robecchi / Alessandra Bossone: <i>Dictionnaire des régionalismes médiévaux: la Galloromania orientale</i> (DRM). <i>Une analyse des</i> Documents linguistiques galloromans (XII<sup>e</sup>-XV<sup>e</sup> s.), Strasbourg, ÉLiPhi, 2024.[<a href=\"/publications/DRM.pdf\">PDF</a>]<div class=\"subline\">[analyse détaillée de <i>ca</i> 400 lexèmes régionaux des DocLing]</div>",
          ],
          '3.1': [
            "Glessgen 2005b, <i>Diskurstraditionen zwischen pragmatischen Regeln und sprachlichen Varietäten.</i>[<a href=\"/publications/Glessgen_2005b_Kopie.pdf\">PDF</a>]<div class=\"subline\">[étude du rôle des genres textuels dans le changement et le fonctionnement linguistiques]</div><span class=\"tooltiptext\">, in: Angela Schrott / Harald Völker (éds): <i>Historische Pragmatik und historische Varietätenlinguistik in den romanischen Sprachen</i>, Göttingen, Universitätsverlag, 207–228</span>",
            "Glessgen 2012: <i>Trajectoires et perspectives en scriptologie galloromane.</i>[<a href=\"/publications/Glessgen_2012_Kopie.pdf\">PDF</a>]<div class=\"subline\">[fondement théorique pour les analyses scriptologiques dans le cadre de GallRom]</div><span class=\"tooltiptext\">, <i>Medioevo Romanzo</i> 36, 5–23</span>",
            "Glessgen 2017, <i>La genèse d’une norme en français au Moyen Âge : mythe et réalité du ‘francien’.</i>[<a href=\"/publications/Glessgen_2017_Kopie.pdf\">PDF</a>]<div class=\"subline\">[étude thématique de l’évolution de la textualité à Paris et des caractéristiques linguistiques de la <i>scripta</i> parisienne]</div><span class=\"tooltiptext\">, <i>Revue de Linguistique Romane</i> 81, 313–398</span>",
          ],
          '3.2': [
            "Glessgen 2004, <i>Realia und Urkunden. Die Teilung eines lothringischen Stadthauses kurz nach 1400.</i>[<a href=\"/publications/Glessgen_2004_Kopie.pdf\">PDF</a>]<div class=\"subline\">[étude lexicologique, diasystématique et textuelle d’une charte lorraine de 1414]</div><span class=\"tooltiptext\">, in: Alberto Gil et al. (éd.): <i>Romanische Sprachwissenschaft. Zeugnisse für Vielfalt und Profil eines Faches</i>. Festschrift für Christian Schmitt zum 60. Geburtstag, Frankfurt a.M. etc., Lang, 423–447</span>",
            "Glessgen 2006a, <i>Vergleichende oder einzelsprachliche historische Textwissenschaft.</i>[<a href=\"/publications/Glessgen_2006a_Kopie.pdf\">PDF</a>]<div class=\"subline\">[étude des marquages diatopique et – surtout – diaphasique dans le lexique des DocLing]</div><span class=\"tooltiptext\">, in: Wolfgang Dahmen et al. (éds), <i>Was kann eine vergleichende romanische Sprachwissenschaft heute (noch) leisten?</i> (Romanistisches Kolloquium XX), Tübingen, Narr, 319–340</span>",
            "Glessgen/Gouvert 2007, <i>La base textuelle du</i> Nouveau Corpus d'Amsterdam: <i>ancrage diasystématique et évaluation philologique.</i>[<a href=\"/publications/Glessgen_Gouvert_2007_Kopie.pdf\">PDF</a>]<div class=\"subline\">[présentation du corpus textuel du <i>Nouveau Corpus d'Amsterdam</i>, correspondant à des extraits de 300 textes non-documentaires français du 13<sup>e</sup> siècle]</div><span class=\"tooltiptext\">, in: Pierre Kunstmann / Achim Stein (éds), <i>Le Nouveau Corpus d'Amsterdam</i> (Actes de l'atelier de Lauterbad, 23-26 février 2006), Stuttgart, Steiner, 51–84</span>",
            "Glessgen 2008: <i>Les 'lieux d’écriture' dans les chartes lorraines du XIII<sup>e</sup> siècle.</i>[<a href=\"/publications/Glessgen_2008_Kopie.pdf\">PDF</a>]<div class=\"subline\">[article fondateur établissant le concept de ‘lieux d’écriture’ et présentant la méthodologie de leur identification dans les actes de la pratique]</div><span class=\"tooltiptext\">, <i>Revue de Linguistique Romane</i> 72, 413–540</span>",
            "Glessgen 2010b: <i>La terre lorraine, les puissants et les hommes : La plus ancienne charte française du scriptorium épiscopal de Toul (1237).</i>[<a href=\"/publications/Glessgen_2010b.pdf\">PDF</a>]<div class=\"subline\">[analyse lexicologique, grapho-phonétique et textuelle d'une charte donnée dans le cadre des chartes épiscopales de Toul réunies dans les DocLing]</div><span class=\"tooltiptext\">, <i>Bibliothèque de l'École des Chartes</i> 168 (2010) [2011], 25–46</span>",
            "Glessgen/Vachon 2013, <i>L'étude philologique et scriptologique du</i> Nouveau Corpus d'Amsterdam[<a href=\"/publications/Glessgen_Vachon_2013_Kopie.pdf\">PDF</a>]<div class=\"subline\">[étude de la méthodologie scriptologique à partir du <i>Nouveau Corpus d'Amsterdam</i>]</div><span class=\"tooltiptext\">, in: Emili Casanova Herrero / Cesáreo Calvo Rigual (éds): <i>Actas del XXVI<sup>e</sup> Congreso Internacional de Lingüística y Filología Románicas</i> (6-11 sept. 2010, València), Berlin, De Gryuter, vol. 7, 191–204</span>",
          ],
          '3.3': [
            "Carles/Glessgen 2019, <i>L'élaboration scripturale du francoprovençal au Moyen Âge.</i>[<a href=\"/publications/Carles_Glessgen_2019_Kopie.pdf\">PDF</a>]<div class=\"subline\">[présentation du corpus francoprovençal des DocLing et analyse exemplaire de l’évolution phonétique de [a] tonique en syllabe libre]</div><span class=\"tooltiptext\">, <i>Zeitschrift für romanische Philologie</i> 135/1, 68–157</span>",
            "Glessgen 2021a, <i>Pour une histoire textuelle du gascon médiéval.</i>[<a href=\"/publications/Glessgen_2021a_Kopie.pdf\">PDF</a>]<div class=\"subline\">[identification et description du corpus gascon du 13<sup>e</sup> siècle pour les DocLing]</div><span class=\"tooltiptext\">, <i>Revue de Linguistique Romane</i> 85, 325–384</span>",
            "Glessgen 2022a, <i>L'étude linguistique du gascon médiéval: analyse scriptologique des genres textuels, Revue de Linguistique Romane</i> 86, 35–94.[<a href=\"/publications/Glessgen_2022a_Kopie.pdf\">PDF</a>]<div class=\"subline\">[analyse scriptologique et lexicologique de la charte de boucherie d'Orthez (1270) et structuration géolinguistique et philologique des textes gascons de coutumes]</div>",
            "Glessgen 2022b, <i>La langue des premiers troubadours – une approche scriptologique.</i>[<a href=\"/publications/Glessgen_2022b_Kopie.pdf\">PDF</a>]<div class=\"subline\">[analyse scriptologique et localisation linguistique des troubadours Guilhem IX, Jaufre Rudel, Cercamon et Marcabru d’après le témoignage chansonniers]</div><span class=\"tooltiptext\">, <i>Medioevo Romanzo</i> 46, 265–329</span>",
            "Glessgen 2023b: <i>“Tout, dans le Codi, pose problème…”; le lieu de genèse et la tradition manuscrite de la “Somme du Code” occitan.</i>[<a href=\"/publications/Glessgen_2023b_Kopie.pdf\">PDF</a>]<div class=\"subline\">[analyse scriptologique et localisation des mss du <i>Codi</i> occitan]</div><span class=\"tooltiptext\">, <i>Revue de Linguistique Romane</i>, 87, 123–160</span>",
          ]
        }
      }
    },
    components: {
      PageTitle,
    },
  }
</script>
